import {
	CLEAR_NEW_CASE_STATE,
	GET_CASE_INFO,
	SET_CASE_ID, SET_PAGE_VALIDATION,
	SET_STARTED_CASE_INFO,
	UNSET_ISLOADED,
} from '../types';


const handlers = {
	[SET_STARTED_CASE_INFO]: (state) => ({...state, title: '', isLoaded: true}),
	[GET_CASE_INFO]: (state, {payload}) => ({...payload, isLoaded: true}),
	[SET_CASE_ID]: (state, {payload}) => ({...state, id: payload}),
	[UNSET_ISLOADED]: (state) => ({...state, isLoaded: false}),
	[SET_PAGE_VALIDATION]: (state, {payload}) => ({...state, pageValidation: payload}),
	[CLEAR_NEW_CASE_STATE]: () => ({
		id: null,
		isLoaded: false,
		title: null,
		sex: null,
		age: null,
		description: null,
		beforeTreatment: {
			description: null,
			tests: null,
		},
		afterTreatment: {
			description: null,
			tests: null,
		},
		conclusion: null,
		pageValidation: {
			p1: false,
			p2: false,
			p3: false,
			p4: false,
			p5: false,
		},
	}),
	DEFAULT: state => state,
}

export const newCaseReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action)
}
import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import UserTable from './UserTable';
import {sortArray} from '../../../utils/helpfunc';
import Select from '../../shared/Select';
import Finder from '../../shared/Finder';

const titleArray = [
	'id', 'drug', 'role', 'author',
]

const filterRolesParams = [
	'All',
	'doctor',
	'supervisor',
	'accountant',
	'moderator',
	'administrator',
]

const UsersMenu = () => {
	
	const {getAllUsers, appState: {drugs, allUsers}} = useContext(AppContext);
	
	const [filterDrugsParams, setFilterDrugsParams] = useState([]);
	const [filterDrugsValue, setFilterDrugsValue] = useState(null);
	const [filterRolesValue, setFilterRolesValue] = useState(filterRolesParams[0]);
	const [finderValue, setFinderValue] = useState('');
	const [drawArray, setDrawArray] = useState([])
	const [activeSort, setActiveSort] = useState({
		sortName: titleArray[0],
		sortAZ: true,
	})
	
	useEffect(() => {
		getAllUsers();
		//eslint-disable-next-line
	}, [])
	
	useEffect(() => {
		if (allUsers && allUsers.length) {
			let tempArray = [];
			
			allUsers.forEach(el => {
				tempArray.push({
					id: el.id,
					name: el.name,
					author: el.surname + el.name,
					surname: el.surname,
					account: el.account,
					email: el.email,
					drug: el.drugs && el.drugs.length ? el.drugs[0].name : null,
					pwz: el.pwz,
					role: el.rolesReadable[0],
					title: el.title,
					telephone: el.telephone,
				})
			})
			
			if (filterDrugsValue !== 'All') {
				tempArray = [...tempArray.filter(el => el.drug === filterDrugsValue)]
			}
			
			if (filterRolesValue !== 'All') {
				tempArray = [...tempArray.filter(el => el.role === filterRolesValue)]
			}
			
			if (finderValue.trim().length !== 0) {
				tempArray = [...tempArray.filter(element => Object.keys(element).some(key =>
						(key === 'name' || key === 'surname')
						&& element[key].toLowerCase().includes(finderValue.toLowerCase()),
					),
				)]
			}
			
			
			tempArray = [...sortArray(activeSort.sortAZ, tempArray, activeSort.sortName)]
			
			
			setDrawArray([...tempArray])
		}
	}, [allUsers, activeSort, filterDrugsValue, filterRolesValue, finderValue])
	
	useEffect(() => {
		if (filterDrugsParams && filterDrugsParams.length > 0) {
			setFilterDrugsValue(filterDrugsParams[0])
		}
		//eslint-disable-next-line
	}, [filterDrugsParams])
	
	useEffect(() => {
		const tempSortDrugsParams = [];
		tempSortDrugsParams.push('All')
		drugs.forEach(el => tempSortDrugsParams.push(el.name))
		setFilterDrugsParams([...tempSortDrugsParams])
	}, [drugs])
	
	const setDrugsFilterHandler = (value) => setFilterDrugsValue(value);
	const setRolesFilterHandler = (value) => setFilterRolesValue(value);
	
	const finderValueHandler = (value) => setFinderValue(value);
	
	const toggleSearchWay = () => setActiveSort(prev => ({...prev, sortAZ: !prev.sortAZ}))
	const changeSortName = sortName => setActiveSort({sortName, sortAZ: true})
	
	return (
		<>
			<div className="users container">
				{
					filterDrugsValue &&
					<div className="users__sortingArea">
						<Select
							value={filterDrugsValue}
							label="Lek"
							name="drugsFilter"
							params={filterDrugsParams}
							sortingValueHandler={setDrugsFilterHandler}
							supervisor
						/>
						<Select
							value={filterRolesValue}
							label="Role"
							name="rolesFilter"
							params={filterRolesParams}
							sortingValueHandler={setRolesFilterHandler}
							supervisor
						/>
						<Finder
							value={finderValue}
							finderValueHandler={finderValueHandler}
						/>
					</div>
				}
				<UserTable
					titleArray={titleArray}
					drawArray={drawArray}
					activeSort={activeSort}
					changeSortName={changeSortName}
					toggleSearchWay={toggleSearchWay}
					addTitle="add user"
				/>
			</div>
		</>
	)
}

export default UsersMenu;
import React, {useContext} from 'react';
import {NewCaseContext} from '../../../context/newCase/newCaseContext';
import {useNavigate} from 'react-router-dom';
import sortAZ from '../../../assets/img/sortAZ.png';
import sortZA from '../../../assets/img/sortZA.png';
import enablePen from '../../../assets/img/enablePen.png';
import disablePen from '../../../assets/img/disablePen.png';


const DoctorTable = ({titleArray, drawArray, activeSort, changeSortName, toggleSearchWay}) => {
	const {setCaseId} = useContext(NewCaseContext);
	const navigate = useNavigate();
	return drawArray.length > 0
		?
		<table className="doctorTable">
			<thead>
			<tr>
				{
					titleArray.map((el, index) =>
						<td key={index} className="doctorTable__titleRow">
							<div className="doctorTable__titleCell">
								<p onClick={() => changeSortName(el.value)}>{el.name}</p>
								{
									activeSort.sortName === el.value
										?
										<img
											onClick={toggleSearchWay}
											src={activeSort.sortAZ ? sortAZ : sortZA}
											alt="sort arrow"
										/>
										:
										<div style={{width: '16px', height: '16px'}}/>
								}
							</div>
						</td>,
					)
				}
				<td/>
			</tr>
			</thead>
			<tbody>
			{
				drawArray.map(el =>
					<tr key={el.id} className="doctorTable__contentRow">
						<td>
							<div className="doctorTable__contentCell">
								<p>{el.id}.</p>
							</div>
						</td>
						<td>
							<div className="doctorTable__contentCell">
								<p>{el.title}</p>
							</div>
						</td>
						<td>
							<div className="doctorTable__contentCell">
								<p>{el.author}</p>
							</div>
						</td>
						<td>
							<div className="doctorTable__contentCell">
								<p>{el.status}</p>
							</div>
						</td>
						<td>
							<div className="doctorTable__contentCell">
								<p>{el.payInfo}</p>
							</div>
						</td>
						<td>
							<div className="doctorTable__contentCell">
								{
									el.status !== 'Zaakceptowane'
										?
										<img
											className="doctorTable__contentCell-enableEdit"
											src={enablePen}
											onClick={() => {
												setCaseId(el.id);
												navigate('/newCase/1');
											}}
											alt="enable edit button"
										/>
										:
										<img src={disablePen} alt="disable edit button"/>
								}
							</div>
						</td>
					</tr>,
				)
			}
			</tbody>
		</table>
		:
		<div className="emptyTableMessage">
			<h1>NO DATA</h1>
		</div>
}

export default DoctorTable;
import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import DashTitle from '../../shared/DashTitle';
import Select from '../../shared/Select';
import CaseCard from '../../shared/CaseCard';
import {sortArray, validDateStr} from '../../../utils/helpfunc';
import Finder from '../../shared/Finder';

const sortParams = [
	{
		name: 'Wg. daty',
		value: 'date',
	},
	{
		name: 'Wg. title',
		value: 'title',
	},
	{
		name: 'Wg. autora',
		value: 'author',
	},
]

const DoctorDash = () => {
	const {appState: {userInfo: {drug}, allCases}, getAllCases} = useContext(AppContext);
	const [sortingValue, setSortingValue] = useState(sortParams[0].value);
	const [finderValue, setFinderValue] = useState('');
	const [drawArray, setDrawArray] = useState(null);
	
	const sortingValueHandler = (value) => setSortingValue(value);
	const finderValueHandler = (value) => setFinderValue(value);
	
	useEffect(()=>{
		getAllCases();
	}, [getAllCases])
	
	useEffect(() => {
		if (allCases && allCases.length) {
			let tempArray = [];
			allCases.forEach(el => tempArray.push({
				id: el['@id'].split('/')[el['@id'].split('/').length - 1],
				author: `${el.user.name} ${el.user.surname}`,
				title: el.title,
				date: el.publishDate,
			}))
			
			if (finderValue.trim().length !== 0) {
				tempArray = [...tempArray.filter(element => Object.keys(element).some(key =>
						key === 'date'
							?
							validDateStr(new Date(element[key])).includes(finderValue.toLowerCase())
							:
							element[key].toLowerCase().includes(finderValue.toLowerCase()),
					),
				)]
			}
			if (sortingValue) {
				tempArray = [...sortArray(true, tempArray, sortingValue)]
			}
			setDrawArray([...tempArray])
		}
		
	}, [sortingValue, finderValue, allCases])
	
	return (
		<div className="doctorDash">
			<div className="container">
				<DashTitle mainTitle="PRZYPADKI" subTitle={drug.diseaseType}/>
				<div className="doctorDash__sortingArea">
					<Select
						value={sortingValue.value}
						label="Sortuj:"
						name="sorting"
						params={sortParams}
						sortingValueHandler={sortingValueHandler}
					/>
					<Finder
						value={finderValue}
						finderValueHandler={finderValueHandler}
					/>
				</div>
				<div className='doctorDash__cardsArea'>
					{drawArray && drawArray.map((el) => <CaseCard key={el.id} caseData={el} drugName={drug.name}/>)}
				</div>
			</div>
		</div>
	)
}

export default DoctorDash
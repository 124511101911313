import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import DashTitle from '../../shared/DashTitle';
import Select from '../../shared/Select';
import Finder from '../../shared/Finder';
import AccountantTable from './AccountantTable';
import {sortArray} from '../../../utils/helpfunc';

const titleArray = [
	{
		name: 'ID',
		value: 'id',
	},
	{
		name: 'Tytuł',
		value: 'title',
	},
	{
		name: 'Autor',
		value: 'author',
	},
	{
		name: 'user info (pdf)',
		value: 'pdf',
	},
	{
		name: 'Status',
		value: 'status',
	},
	{
		name: 'Rozliczenie',
		value: 'isPaid',
	},
]

const AccountantDash = () => {
	const {appState: {drugs, allCases}, getAllCases} = useContext(AppContext);
	const [filterDrugsValue, setFilterDrugsValue] = useState(null);
	const [filterDrugsParams, setFilterDrugsParams] = useState([]);
	const [finderValue, setFinderValue] = useState('');
	const [drawArray, setDrawArray] = useState(null);
	const [activeSort, setActiveSort] = useState({
		sortName: titleArray[0].value,
		sortAZ: true,
	})
	
	useEffect(() => getAllCases(), [getAllCases]);
	
	useEffect(() => {
		if (filterDrugsValue && allCases.length) {
			let tempArray = [];
			allCases.forEach(el => tempArray.push({
				id: el['@id'].split('/')[el['@id'].split('/').length - 1],
				title: el.title,
				author: `${el.user.surname} ${el.user.name}`,
				status: el.status,
				isPaid: el.isPaid,
				drug: el.drug.name,
				userEmail: el.user.email,
				userTelephone: el.user.telephone,
			}))
			
			if (filterDrugsValue !== 'All') {
				tempArray = [...tempArray.filter(el => el.drug === filterDrugsValue)]
			}
			
			if (finderValue.trim().length !== 0) {
				tempArray = [...tempArray.filter(element => Object.keys(element).some(key =>
						key !== 'userTelephone'
						&& key !== 'userEmail'
						&& key !== 'isPaid'
						&& key !== 'drug'
						&& element[key].toLowerCase().includes(finderValue.toLowerCase()),
					),
				)]
			}
			
			if (activeSort.sortName !== 'pdf') {
				tempArray = [...sortArray(activeSort.sortAZ, tempArray, activeSort.sortName)]
			}
			
			setDrawArray([...tempArray]);
		}
	}, [finderValue, filterDrugsValue, allCases, activeSort])
	
	useEffect(() => {
		if (filterDrugsParams && filterDrugsParams.length > 0) {
			setFilterDrugsValue(filterDrugsParams[0])
		}
		//eslint-disable-next-line
	}, [filterDrugsParams])
	
	useEffect(() => {
		const tempSortDrugsParams = [];
		tempSortDrugsParams.push('All')
		drugs.forEach(el => tempSortDrugsParams.push(el.name))
		setFilterDrugsParams([...tempSortDrugsParams])
	}, [drugs])
	
	const setDrugsFilterHandler = (value) => setFilterDrugsValue(value)
	const finderValueHandler = (value) => setFinderValue(value);
	const toggleSearchWay = () => setActiveSort(prev => ({...prev, sortAZ: !prev.sortAZ}))
	const changeSortName = sortName => setActiveSort({sortName, sortAZ: true})
	
	return (
		<div className="accountant container">
			<DashTitle mainTitle="PRZYPADKI"/>
			{
				filterDrugsValue &&
				<div className="accountant__sortingArea">
					<Select
						value={filterDrugsValue}
						label="Lek"
						name="drugsFilter"
						params={filterDrugsParams}
						sortingValueHandler={setDrugsFilterHandler}
						supervisor
					/>
					<Finder
						value={finderValue}
						finderValueHandler={finderValueHandler}
					/>
				</div>
			}
			<AccountantTable
				titleArray={titleArray}
				drawArray={drawArray}
				activeSort={activeSort}
				changeSortName={changeSortName}
				toggleSearchWay={toggleSearchWay}
			/>
		</div>
	)
}

export default AccountantDash;
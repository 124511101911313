import axios from 'axios';
import {APIURL} from '../consts/APIURL';

export default axios.create(
	{
		baseURL: APIURL,
		headers: {
			'Content-Type': 'application/json',
		},
	},
)
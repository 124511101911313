import React, {useContext, useEffect, useState} from 'react';
import DashTitle from '../../shared/DashTitle';
import {AppContext} from '../../../context/app/appContext';
import DoctorTable from './DoctorTable';
import LocalLoader from '../../shared/LocalLoader';
import {sortArray} from '../../../utils/helpfunc';
import Finder from '../../shared/Finder';

const titleArray = [
	{
		name: 'ID',
		value: 'id',
	},
	{
		name: 'Tytuł',
		value: 'title',
	},
	{
		name: 'Autor',
		value: 'author',
	},
	{
		name: 'Status',
		value: 'status',
	},
	{
		name: 'Rozliczenie',
		value: 'payInfo',
	},
]

const MyCases = () => {
	const {
		appState: {userInfo: {drug, name: userName, surname: userSurname}, userCases},
		getUserCases,
	} = useContext(AppContext);
	const [drawArray, setDrawArray] = useState(null);
	const [finderValue, setFinderValue] = useState('');
	const [activeSort, setActiveSort] = useState({
		sortName: titleArray[0].value,
		sortAZ: true,
	})
	
	useEffect(() => {
		getUserCases()
	}, [getUserCases])
	
	useEffect(() => {
		if (userCases) {
			let tempArray = []
			userCases.forEach(el => tempArray.push({
				id: el['@id'].split('/')[el['@id'].split('/').length - 1],
				title: el.title,
				author: `${userName} ${userSurname}`,
				status: el.status,
				payInfo: el.isPaid ? 'Opłacone' : 'Nieopłacone',
			}))
			if (finderValue.trim().length !== 0) {
				tempArray = [...tempArray.filter(element => Object.keys(element).some(key => element[key].toLowerCase().includes(finderValue.toLowerCase())))]
			}
			
			tempArray = [...sortArray(activeSort.sortAZ, tempArray, activeSort.sortName)]
			
			setDrawArray([...tempArray])
		}
		
	}, [activeSort, finderValue, userCases, userName, userSurname])
	
	const toggleSearchWay = () => setActiveSort(prev => ({...prev, sortAZ: !prev.sortAZ}))
	const changeSortName = sortName => setActiveSort({sortName, sortAZ: true})
	const finderValueHandler = (value) => setFinderValue(value);
	
	return (
		<div className="myCases container">
			<DashTitle mainTitle="TWOJE PRZYPADKI" subTitle={drug.diseaseType}/>
			<div className="myCases__finderArea">
				<Finder value={finderValue} finderValueHandler={finderValueHandler}/>
			</div>
			<div className="myCases__content">
				{
					!drawArray
						?
						<LocalLoader/>
						:
						<DoctorTable
							titleArray={titleArray}
							drawArray={drawArray}
							activeSort={activeSort}
							toggleSearchWay={toggleSearchWay}
							changeSortName={changeSortName}
						/>
				}
			</div>
		</div>
	)
}

export default MyCases;
import React, {useContext, useEffect} from 'react';
import Button from '../formElements/Button';
import FormInput from '../formElements/FormInput';
import {VALIDATOR_REQUIRE} from '../../../utils/validators';
import {useForm} from '../../../hooks/form-hook';
import ImageUploads from '../formElements/ImageUploads';
import {AppContext} from '../../../context/app/appContext';

const DrugModal = ({closeModal, defaultDrugInfo, clearModalState}) => {
	
	const {addNewDrug, editDrug} = useContext(AppContext);
	
	const [drugInfo, drugInfoHandler, setFormData] = useForm({
		name: {
			value: '',
			isValid: false,
		},
		diseaseType: {
			value: '',
			isValid: false,
		},
		mainColor: {
			value: '',
			isValid: false,
		},
		secondColor: {
			value: '',
			isValid: false,
		},
		imageArrowFile: {
			value: null,
			isValid: true,
		},
		imageThumbFile: {
			value: null,
			isValid: true,
		},
	}, !!defaultDrugInfo);
	
	useEffect(() => {
		if (defaultDrugInfo) {
			setFormData({
				name: {
					value: defaultDrugInfo.name,
					isValid: true,
				},
				diseaseType: {
					value: defaultDrugInfo.diseaseType,
					isValid: true,
				},
				mainColor: {
					value: defaultDrugInfo.mainColor,
					isValid: true,
				},
				secondColor: {
					value: defaultDrugInfo.secondColor,
					isValid: true,
				},
				imageArrowFile: {
					value: null,
					isValid: true,
				},
				imageThumbFile: {
					value: null,
					isValid: true,
				},
			}, true)
		}
	}, [defaultDrugInfo, setFormData])
	
	const confirmHandler = () => {
		const data = new FormData();
		data.append('name', drugInfo.inputs.name.value);
		data.append('diseaseType', drugInfo.inputs.diseaseType.value);
		data.append('mainColor', drugInfo.inputs.mainColor.value);
		data.append('secondColor', drugInfo.inputs.secondColor.value);
		data.append('imageArrowFile', drugInfo.inputs.imageArrowFile.value);
		data.append('imageThumbFile', drugInfo.inputs.imageThumbFile.value);
		defaultDrugInfo ? editDrug(defaultDrugInfo.id, data) : addNewDrug(data);
	}
	
	return (
		<div className="drugModal">
			<div className="drugModal__content">
				<h3>Edit/Add drug</h3>
				<FormInput
					id="name"
					element="input"
					type="text"
					label="drug name"
					validators={[VALIDATOR_REQUIRE()]}
					errorText="check drug name"
					initialValue={defaultDrugInfo.name}
					initialValid={true}
					onInput={drugInfoHandler}
				/>
				<FormInput
					id="diseaseType"
					element="input"
					type="text"
					label="disease type"
					validators={[VALIDATOR_REQUIRE()]}
					errorText="check disease type"
					initialValue={defaultDrugInfo.diseaseType}
					initialValid={true}
					onInput={drugInfoHandler}
				/>
				<FormInput
					id="mainColor"
					element="input"
					type="text"
					label="main color"
					validators={[VALIDATOR_REQUIRE()]}
					errorText="check main color"
					initialValue={defaultDrugInfo.mainColor}
					initialValid={true}
					onInput={drugInfoHandler}
				/>
				<FormInput
					id="secondColor"
					element="input"
					type="text"
					label="second color"
					validators={[VALIDATOR_REQUIRE()]}
					errorText="check second color"
					initialValue={defaultDrugInfo.secondColor}
					initialValid={true}
					onInput={drugInfoHandler}
				/>
				<ImageUploads
					center
					id="imageArrowFile"
					onInput={drugInfoHandler}
					errorText="chose arrow img"
					arrow
				/>
				<ImageUploads
					center
					id="imageThumbFile"
					onInput={drugInfoHandler}
					errorText="chose thumb img"
					thumb
				/>
				<div className="drugModal__content-buttonArea">
					<Button onClick={() => {
						clearModalState();
						closeModal()
					}} type="button">Powrót</Button>
					<Button
						type="button"
						disabled={!drugInfo.isValid}
						onClick={confirmHandler}
					>
						Zapisz
					</Button>
				</div>
			</div>
		</div>
	)
}

export default DrugModal;
import React from 'react';

const RangeInput = ({value, onChangeHandler, min, max, step, questionIndex}) => {
	const scaleDraw = []
	for (let i = min; i <= max; i = i + step) {
		scaleDraw.push(i);
	}
	return (
		<div className="rangeInput">
			<div className="rangeInput__scale">
				{scaleDraw.length > 0 && scaleDraw.map((el, index) => <div key={index}/>)}
			</div>
			<input
				className="rangeInput__input"
				type="range"
				step={step}
				min={min}
				max={max}
				value={value}
				onChange={(event) => onChangeHandler(questionIndex, +event.target.value)}
				list="tickmarks"
			/>
			<datalist className="rangeInput__dataList" id="tickmarks">
				{scaleDraw.length > 0 && scaleDraw.map((el, index) => <option key={index} value={el}>{el}</option>)}
			</datalist>
		</div>
	)
}

export default RangeInput;
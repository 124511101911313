import React, {useContext, useEffect, useState} from 'react';
import {NewCaseContext} from '../../../../context/newCase/newCaseContext';
import NavButton from '../../../shared/NavButton';
import QuillData from '../../../shared/QuillData';
import Button from '../../../shared/formElements/Button';
import CustomTestModal from '../../../shared/modals/CustomTestModal';

const SubInfo = ({pageNumber}) => {
	const {newCaseState, newCaseState: {pageValidation}, editCase} = useContext(NewCaseContext);
	const [isValidPage, setIsValidPage] = useState(false);
	const [tests, setTests] = useState(+pageNumber === 3 ? [...newCaseState.beforeTreatment.tests] : [...newCaseState.afterTreatment.tests])
	const [openTestNumber, setOpenTestNumber] = useState(null);
	const [quillData, setQuillData] = useState(+pageNumber === 3 ? newCaseState.beforeTreatment.description : newCaseState.afterTreatment.description);
	
	useEffect(() => {
		quillData && quillData.length > 0 && quillData !== '<p><br></p>' ? setIsValidPage(true) : setIsValidPage(false);
	}, [quillData])
	
	const quillSetterHandler = (value) => setQuillData(value);
	const closeAllPopups = () => setOpenTestNumber(null)
	
	const paramSaver = (value, testNumber) => {
		setTests(prev => {
			const tempTests = [...prev]
			tempTests[testNumber] = {...value}
			return ([...tempTests])
		})
	}
	
	const onSaveHandler = () => {
		if (+pageNumber === 3) {
			editCase(null, null, null, null, {description: quillData, tests}, null, null);
		} else {
			editCase(null, null, null, null, null, {description: quillData, tests}, null);
		}
		
	}
	
	return (
		<>
			<div className="subInfo">
				<NavButton isActive={true} to={`/newCase/${+pageNumber === 3 ? '2' : '3'}`} left/>
				<div className="subInfo__content">
					<div className="subInfo__content-dataArea">
						{
							+pageNumber === 3
								?
								<>
									<h3>Dotychczasowe leczenie i podsumowanie</h3>
									<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id integer massa eget
										pulvinar est.
										Ipsum dignissim varius sem etiam tristique tempor, in euismod commodo.</h6>
								</>
								:
								<>
									<h3>Interwencja i ocena efektu</h3>
									<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id integer massa eget
										pulvinar est.
										Ipsum dignissim varius sem etiam tristique tempor, in euismod commodo.</h6>
								</>
						}
						<QuillData quillData={quillData} quillSetterHandler={quillSetterHandler}/>
						{
							tests && tests.map((singleTest, testIndex) => (
								<Button
									key={testIndex}
									type="button"
									buttonClassName="showModalButton"
									onClick={() => {
										setOpenTestNumber(testIndex)
									}}
								>
									{singleTest.title}
								</Button>
							))
						}
					</div>
					<div className="subInfo__content-buttonArea">
						<Button to="/">ANULUJ</Button>
						<Button
							type="button"
							disabled={!isValidPage}
							onClick={onSaveHandler}
						>ZAPISZ</Button>
					</div>
				</div>
				<NavButton
					isActive={+pageNumber === 3 ? pageValidation && pageValidation.p3 : pageValidation && pageValidation.p4}
					to={`/newCase/${+pageNumber === 3 ? '4' : '5'}`}
				/>
			</div>
			{
				openTestNumber !== null &&
				<CustomTestModal
					testNumber={openTestNumber}
					singleTest={tests[openTestNumber]}
					closePopup={closeAllPopups}
					paramSaver={paramSaver}
				/>
			}
		</>
	)
}

export default SubInfo;
import React from 'react';

const RegistrationSelect = ({value, id, defaultValueTitle, onChangeHandler, variantsArray}) => {
	return (
		<div className="registrationSelect">
			<label className="customSelect" htmlFor="payFormat">
				<select
					id={id}
					value={value}
					onChange={(e) => onChangeHandler(e.target.value)}
				>
					<option value="" disabled={true}>{defaultValueTitle}</option>
					{
						variantsArray && variantsArray.map(element => <option key={element.name} value={element.value}>{element.name}</option>)
					}
				</select>
			</label>
		</div>
	)
}

export default RegistrationSelect;
import React, {useContext, useEffect, useState} from 'react';
import {PoiContext} from '../context/poi/poiContext';
import {AuthContext} from '../context/auth/authContext';
import {AppContext} from '../context/app/appContext';
import Header from './shared/Header';
import DynamicStyles from './shared/DynamicStyles';
import protectedRoutes from '../routes/protectedRoutes';
import GlobalLoader from './shared/GlobalLoader';
import ErrorModal from './shared/modals/ErrorModal';

function App() {
	const {isGlobalError, isGlobalLoader} = useContext(PoiContext);
	const {isLoggedIn} = useContext(AuthContext);
	const {appState: {drugs, userInfo: {role}}, getDrugs} = useContext(AppContext);
	const [routes, setRoutes] = useState();
	
	useEffect(() => {
		setRoutes(protectedRoutes(isLoggedIn, role))
	}, [isLoggedIn, role])
	
	useEffect(()=>{
		drugs.length === 0 && getDrugs();
	}, [getDrugs, drugs.length])
	
	return (
		<div className="App">
			<Header/>
			{isLoggedIn && !role ? <GlobalLoader/> : routes}
			<DynamicStyles/>
			{isGlobalLoader && <GlobalLoader/>}
			{isGlobalError && <ErrorModal/>}
			<a
				className='App__ppLink'
				href="../assets/pdf/SERWIS_HCP_Portal_Polityka_Prywatności_(01.04.2020)%5B32%5D.pdf"
				download="Politykę prywatności.pdf">Polityka prywatności</a>
		</div>
	);
}

export default App;

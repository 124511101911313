import React, {useContext} from 'react';
import {PoiContext} from '../../../context/poi/poiContext';
import Button from '../formElements/Button';

const ErrorModal = () => {
	const {errorMessage, errorCode, unsetModalError} = useContext(PoiContext);
	return (
		<div className="errorModal">
			<div className="errorModal__content">
				<h3>Message</h3>
				<p>
					<span
						style={{color: errorCode >= 200 && errorCode < 300 ? 'green' : 'red'}}>
						{errorCode}:
					</span>
					{errorMessage}
				</p>
				<Button onClick={unsetModalError} type="button">Powrót</Button>
			</div>
		</div>
	)
}

export default ErrorModal;
import React from 'react';

const Select = ({name, params, value, sortingValueHandler, label, supervisor, moderator}) => {

	return (
		<label className="customSelect" htmlFor={name}>
			{label}:
			<select
				name={name}
				id={name}
				value={value}
				onChange={(e) => sortingValueHandler(e.target.value)}
			>
				{
					supervisor || moderator
						?
						params.map((el, index) => <option key={index} value={el}>{el}</option>)
						:
						params.map((el, index) => <option key={index} value={el.value}>{el.name}</option>)
				}
			</select>
		</label>
	)
}

export default Select
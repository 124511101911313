import React from 'react';
import {NavLink} from 'react-router-dom';

const Button = props => {
	if (props.href) {
		return (
			<a
				className={props.buttonClassName ? props.buttonClassName : 'button'}
				href={props.href}
			>
				{props.children}
			</a>
		);
	}
	if (props.to) {
		return (
			<NavLink
				to={props.to}
				exact={props.exact}
				className={props.buttonClassName ? props.buttonClassName : 'linkButton'}
			>
				{props.children}
			</NavLink>
		);
	}
	return (
		<button
			className={props.buttonClassName ? props.buttonClassName : 'button'}
			type={props.type}
			onClick={props.onClick}
			disabled={props.disabled}
		>
			{props.children}
		</button>
	);
};

export default Button;
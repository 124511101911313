import React, {useCallback, useContext, useEffect, useReducer} from 'react';
import {appReducer} from './appReducer';
import {AppContext} from './appContext';
import {AuthContext} from '../auth/authContext';
import axios from '../../axios/axios';
import {PoiContext} from '../poi/poiContext';
import {
	CLEAR_APP_STATE,
	GET_ALL_CASES,
	GET_ALL_DRUGS,
	GET_ALL_USERS,
	GET_DRUGS,
	GET_USER_CASES,
	GET_USER_INFO,
} from '../types';

export const AppState = ({children}) => {
	const {setModalError, setGlobalLoader, unsetGlobalLoader} = useContext(PoiContext);
	const {isLoggedIn, userId, token} = useContext(AuthContext);
	const initialState = {
		userInfo: {
			userTitle: null,
			role: null,
			name: null,
			surname: null,
			email: null,
			telephone: null,
			account: null,
			drug: {
				id: null,
				name: null,
				diseaseType: null,
				mainColor: null,
				secondColor: null,
				arrow: null,
				scrollThumb: null,
			},
		},
		userCases: [],
		allCases: [],
		allUsers: [],
		allDrugs: [],
		drugs: [],
	}

	const [state, dispatch] = useReducer(appReducer, initialState);
	
	useEffect(() => {
		!token && clearAppState()
	}, [token])
	
	const getUserInfo = useCallback(async () => {
		try {
			await axios.get(
				`users/${userId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				const payload = {
					userTitle: res.data.title,
					role: res.data.rolesReadable[0],
					name: res.data.name,
					surname: res.data.surname,
					email: res.data.email,
					telephone: res.data.telephone,
					account: res.data.account,
					drug: res.data.drugs && res.data.drugs.length !== 0
						?
						{
							name: res.data.drugs[0].name,
							id: res.data.drugs[0].id,
							diseaseType: res.data.drugs[0].diseaseType,
							mainColor: res.data.drugs[0].mainColor,
							secondColor: res.data.drugs[0].secondColor,
							arrow: res.data.drugs[0].imageArrow,
							scrollThumb: res.data.drugs[0].imageThumb,
						}
						:
						{
							name: null,
							id: null,
							diseaseType: null,
							mainColor: null,
							secondColor: null,
							arrow: null,
							scrollThumb: null,
						},
				}
				dispatch({
					type: GET_USER_INFO,
					payload,
				})
			})
		} catch (e) {
			setModalError(e.response);
			// setModalError(e.response.data.message, e.response.data.code);
		}
	}, [setModalError, token, userId])
	
	useEffect(() => {
		if (isLoggedIn && userId && token && !state.userInfo.role) {
			getUserInfo()
		}
	}, [state.userInfo.role, isLoggedIn, userId, getUserInfo, token])
	
	const changeUserInfo = async (title, telephone, account) => {
		setGlobalLoader()
		
		const data = {
			title,
			telephone,
			account,
		}
		
		try {
			await axios.put(
				`users/${userId}`,
				data,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				let defRes = {
					data: {
						code: 200,
						message: 'Zmieniono informacje o użytkowniku',
					},
				}
				setModalError(defRes);
				unsetGlobalLoader()
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	
	const getDrugs = useCallback(async () => {
		try {
			await axios.get(`drugs`).then(res => {
				dispatch({type: GET_DRUGS, payload: res.data['hydra:member']});
			})
		} catch (e) {
			setModalError(e.response);
		}
	}, [setModalError])
	
	
	const getAllCases = useCallback(async () => {
		if (token) {
			setGlobalLoader()
			try {
				await axios.get(
					'cases',
					{
						headers: {Authorization: `Bearer ${token}`},
					},
				).then(res => {
					dispatch({type: GET_ALL_CASES, payload: res.data['hydra:member']});
					unsetGlobalLoader()
				})
			} catch (e) {
				unsetGlobalLoader()
				setModalError(e.response);
			}
		}
		//eslint-disable-next-line
	}, [token])
	
	const getUserCases = useCallback(async () => {
		if (token) {
			setGlobalLoader()
			try {
				await axios.get(
					'cases-owned',
					{
						headers: {Authorization: `Bearer ${token}`},
					},
				).then(res => {
					dispatch({type: GET_USER_CASES, payload: res.data['hydra:member']});
					unsetGlobalLoader()
				})
			} catch (e) {
				unsetGlobalLoader()
				setModalError(e.response);
			}
		}
		//eslint-disable-next-line
	}, [token])
	
	const getAllUsers = async () => {
		setGlobalLoader()
		try {
			await axios.get(
				'admin/users',
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				dispatch({type: GET_ALL_USERS, payload: res.data['hydra:member']});
				unsetGlobalLoader()
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	
	const getCasePDF = async (caseId) => {
		try {
			await axios.get(
				`cases/${caseId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
					responseType: 'blob',
				},
			).then(res => {
				let blob = new Blob([res.data], {type: 'application/pdf'})
				let win = window.open('', '_blank')
				let URL = window.URL || window.webkitURL;
				win.location = URL.createObjectURL(blob)
			})
		} catch (e) {
			setModalError(e.response);
		}
	}
	
	const getUserPDF = async (userId) => {
		try {
			await axios.get(
				`user/${userId}/pdf`,
				{
					headers: {Authorization: `Bearer ${token}`},
					responseType: 'blob',
				},
			).then(res => {
				let blob = new Blob([res.data], {type: 'application/pdf'})
				let win = window.open('', '_blank')
				let URL = window.URL || window.webkitURL;
				win.location = URL.createObjectURL(blob)
			})
		} catch (e) {
			setModalError(e.response);
		}
	}
	
	const changePayStatus = async (id) => {
		setGlobalLoader();
		try {
			await axios.post(
				`cases/${id}/paid`,
				{},
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				getAllCases();
				let defRes = {
					data: {
						code: 200,
						message: 'Status został zmieniony',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const changeStatus = async (id, status, mnumber) => {
		setGlobalLoader();
		try {
			switch (status) {
				case 'uwagi':
					await axios.post(
						`cases/${id}/remarks`,
						{},
						{
							headers: {Authorization: `Bearer ${token}`},
						},
					).then(res => {
						unsetGlobalLoader();
						getAllCases();
						let defRes = {
							data: {
								code: 200,
								message: 'Status został zmieniony',
							},
						}
						setModalError(defRes);
					})
					break
				case 'zamknięte':
					await axios.post(
						`cases/${id}/closed`,
						{},
						{
							headers: {Authorization: `Bearer ${token}`},
						},
					).then(res => {
						unsetGlobalLoader();
						getAllCases();
						let defRes = {
							data: {
								code: 200,
								message: 'Status został zmieniony',
							},
						}
						setModalError(defRes);
					})
					break
				case 'zaakceptowane nie do publikacji':
					await axios.post(
						`cases/${id}/nonpublish`,
						{},
						{
							headers: {Authorization: `Bearer ${token}`},
						},
					).then(res => {
						unsetGlobalLoader();
						getAllCases();
						let defRes = {
							data: {
								code: 200,
								message: 'Status został zmieniony',
							},
						}
						setModalError(defRes);
					})
					break
				case 'zaakceptowane do publikacji':
					const data = {
						medicalSignature: mnumber,
					}
					await axios.post(
						`cases/${id}/publish`,
						data,
						{
							headers: {Authorization: `Bearer ${token}`},
						},
					).then(res => {
						unsetGlobalLoader();
						getAllCases();
						let defRes = {
							data: {
								code: 200,
								message: 'Status został zmieniony',
							},
						}
						setModalError(defRes);
					})
					break
				default:
					unsetGlobalLoader();
					let defRes = {
						data: {
							code: 400,
							message: 'Spróbuj później',
						},
					}
					setModalError(defRes);
			}
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	
	const addNewUser = async (userInfo) => {
		setGlobalLoader();
		try {
			const data = {
				name: userInfo.name.value,
				surname: userInfo.surname.value,
				email: userInfo.email.value,
				telephone: userInfo.telephone.value,
				rolesReadable: [userInfo.roleFormat],
				password: 'Password!1',
				confirmPassword: 'Password!1',
			}
			await axios.post(
				`admin/users`,
				data,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				getAllUsers();
				let defRes = {
					data: {
						code: 200,
						message: 'Uzytkownik został dodany',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}

	const addNewDrug = async (drugInfo) => {
		setGlobalLoader();
		try {
			await axios.post(
				`admin/drugs`,
				drugInfo,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				getAllDrugs();
				let defRes = {
					data: {
						code: 200,
						message: 'Uzytkownik został dodany',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const deleteUser = async (id) => {
		setGlobalLoader()
		try {
			await axios.delete(
				`admin/users/${id}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				getAllUsers();
				let defRes = {
					data: {
						code: 200,
						message: 'Uzytkownik został usunięty',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const getAllDrugs = async () => {
		setGlobalLoader()
		try {
			await axios.get(
				'admin/drugs',
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				dispatch({type: GET_ALL_DRUGS, payload: res.data['hydra:member']});
				unsetGlobalLoader()
			})
		} catch (e) {
			unsetGlobalLoader()
			setModalError(e.response);
		}
	}
	
	const editDrug = async (id, data) => {
		setGlobalLoader();
		try {
			await axios.post(
				`admin/drugs/${id}`,
				data,
				{
					headers: {Authorization: `Bearer ${token}`},
				},
			).then(res => {
				unsetGlobalLoader();
				getAllDrugs();
				let defRes = {
					data: {
						code: 200,
						message: 'Uzytkownik został dodany',
					},
				}
				setModalError(defRes);
			})
		} catch (e) {
			unsetGlobalLoader();
			setModalError(e.response);
		}
	}
	
	const clearAppState = () => dispatch({type: CLEAR_APP_STATE});
	
	return (
		<AppContext.Provider value={{
			appState: state,
			changeUserInfo,
			getDrugs,
			getAllCases,
			getAllUsers,
			getUserCases,
			getCasePDF,
			getAllDrugs,
			changePayStatus,
			changeStatus,
			addNewUser,
			addNewDrug,
			deleteUser,
			clearAppState,
			editDrug,
			getUserPDF,
		}}>
			{children}
		</AppContext.Provider>
	)
}

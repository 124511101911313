import React from 'react';
import Button from '../formElements/Button';

const ContactModal = ({author, telephone, email, closeModal}) => {
	return (
		<div className='contactModal'>
			<div className="contactModal__content">
				<h3>Doctor contact:</h3>
				<h2>{author}</h2>
				<a href={`tel:${telephone}`}><span>Telephone:</span> {telephone}</a>
				<a href={`mailto:${email}`}><span>Email:</span> {email}</a>
				<Button onClick={closeModal} type="button">Powrót</Button>
			</div>
		</div>
	)
}

export default ContactModal;
import React, {useContext} from 'react';
import {NavLink} from 'react-router-dom';
import {NewCaseContext} from '../../../context/newCase/newCaseContext';

const NewCaseMenu = () => {
	const {newCaseState: {pageValidation}} = useContext(NewCaseContext);
	
	return (
		<div className="newCaseMenu">
			<div className="container">
				<nav>
					<NavLink
						className={({isActive}) => `newCaseMenu__link ${isActive && 'newCaseMenu__activeLink'}`}
						to={`/newCase/1`}
					>
						Dane
					</NavLink>
					{
						pageValidation &&
						!pageValidation.p1
							?
							<p className="newCaseMenu__fLink">
								Informacje kliniczne
							</p>
							:
							<NavLink
								className={({isActive}) => `newCaseMenu__link ${isActive && 'newCaseMenu__activeLink'}`}
								to="/newCase/2"
							>
								Informacje kliniczne
							</NavLink>
					}
					{
						pageValidation &&
						!pageValidation.p2
							?
							<p className="newCaseMenu__fLink">
								Dotychczasowe leczenie
							</p>
							:
							<NavLink
								className={({isActive}) => `newCaseMenu__link ${isActive && 'newCaseMenu__activeLink'}`}
								to="/newCase/3"
							>
								Dotychczasowe leczenie
							</NavLink>
					}
					{
						pageValidation &&
						!pageValidation.p3
							?
							<p className="newCaseMenu__fLink">
								Interwencja i ocena efektu
							</p>
							:
							<NavLink
								className={({isActive}) => `newCaseMenu__link ${isActive && 'newCaseMenu__activeLink'}`}
								to="/newCase/4"
							>
								Interwencja i ocena efektu
							</NavLink>
					}
					{
						pageValidation &&
						!pageValidation.p3
							?
							<p className="newCaseMenu__fLink">
								Podsumowanie
							</p>
							:
							<NavLink
								className={({isActive}) => `newCaseMenu__link ${isActive && 'newCaseMenu__activeLink'}`}
								to="/newCase/5"
							>
								Podsumowanie
							</NavLink>
					}
				</nav>
			</div>
		</div>
	)
}

export default NewCaseMenu;
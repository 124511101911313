import React, {useState} from 'react';
import Button from '../formElements/Button';
import ModalInput from './modalElements/ModalInput';
import RangeInput from './modalElements/RangeInput';

const CustomTestModal = ({singleTest, closePopup, paramSaver, testNumber}) => {
	
	const [test, setTest] = useState(JSON.parse(JSON.stringify(singleTest)))
	
	const onChangeHandler = (index, value) => {
		setTest(prev => {
			let tempQuestions = [...prev.fields]
			tempQuestions[index].value = value
			return ({...prev, fields: [...tempQuestions]})
		})
	}
	
	const submitFormHandler = (e) => {
		e.preventDefault();
		paramSaver(test, testNumber);
		closePopup()
	}
	
	
	return (
		<div className="modal">
			<form
				className="modal__wrapper"
				onSubmit={submitFormHandler}
			>
				<h1 className="modal__wrapper-title">{singleTest.title}</h1>
				<div className="modal__wrapper-content">
					{test.fields.map((question, questionIndex) =>
						question.type === 'number'
							?
							<ModalInput
								key={questionIndex}
								questionIndex={questionIndex}
								id={question.label}
								label={question.label}
								min={+question.min}
								max={+question.max}
								value={question.value ? +question.value : 0}
								onInput={onChangeHandler}
							/>
							:
							<RangeInput
								key={questionIndex}
								questionIndex={questionIndex}
								value={+question.value}
								min={+question.min}
								max={+question.max}
								step={+question.step}
								onChangeHandler={onChangeHandler}
							/>,
					)}
				</div>
				<div className="modal__wrapper-buttonArea">
					<Button onClick={closePopup} type="button">Powrót</Button>
					<Button
						type="submit"
					>
						ZAPISZ ZMIANY
					</Button>
				</div>
			</form>
		</div>
	)
}

export default CustomTestModal;
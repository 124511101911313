import {SET_GLOBAL_LOADER, SET_MODAL_ERROR, UNSET_GLOBAL_LOADER, UNSET_MODAL_ERROR} from '../types';


const handlers = {
	[SET_GLOBAL_LOADER]: (state) => ({...state, isGlobalLoader: true}),
	[UNSET_GLOBAL_LOADER]: (state) => ({...state, isGlobalLoader: false}),
	[SET_MODAL_ERROR]: (state, {payload}) => ({...state, isGlobalError: true, ...payload}),
	[UNSET_MODAL_ERROR]: (state) => ({
		...state,
		isGlobalError: false,
		errorMessage: null,
		errorCode: null,
	}),
	DEFAULT: state => state,
}

export const poiReducer = (state, action) => {
	const handle = handlers[action.type] || handlers.DEFAULT;
	return handle(state, action)
}
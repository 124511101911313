import React, {useContext, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import {useForm} from '../../../hooks/form-hook';
import {VALIDATOR_EMAIL, VALIDATOR_LENGTH, VALIDATOR_NUMBER, VALIDATOR_REQUIRE} from '../../../utils/validators';
import FormInput from '../formElements/FormInput';
import Button from '../formElements/Button';
import RegistrationSelect from '../../pages/auth/RegistrationSelect';

const AddUserModal = ({closeModal}) => {
	
	const {addNewUser} = useContext(AppContext);
	
	const [roleFormat, setRoleFormat] = useState('');
	
	const roles = [
		{
			value: 'supervisor',
			name: 'Nadzorujący',
		},
		{
			value: 'accountant',
			name: 'Rozliczający',
		},
		{
			value: 'moderator',
			name: 'Akceptujący',
		},
		{
			value: 'administrator',
			name: 'Administrator',
		},
	]
	
	const [formState, inputHandler] = useForm({
		name: {
			value: '',
			isValid: false,
		},
		surname: {
			value: '',
			isValid: false,
		},
		email: {
			value: '',
			isValid: false,
		},
		telephone: {
			value: '',
			isValid: false,
		},
		role: {
			value: '',
			isValid: false,
		},
	}, false)
	
	const roleFormatSetter = value => setRoleFormat(value);
	
	const addUserInfo = () => {
		addNewUser({...formState.inputs, roleFormat});
		closeModal();
	}
	
	return (
		<div className="addUserModal">
			<div className="addUserModal__content">
				<h3>User registration info:</h3>
				<div>
					<FormInput
						id="name"
						element="input"
						type="text"
						label="Imię"
						validators={[VALIDATOR_REQUIRE()]}
						errorText="sprawdź swoje imię"
						onInput={inputHandler}
					/>
					<FormInput
						id="surname"
						element="input"
						type="text"
						label="Nazwisko"
						validators={[VALIDATOR_REQUIRE()]}
						errorText="sprawdź swoje nazwisko"
						onInput={inputHandler}
					/>
					<FormInput
						id="email"
						element="input"
						type="email"
						label="Adres e-mail"
						validators={[VALIDATOR_EMAIL()]}
						errorText="sprawdź swoj e-mail"
						onInput={inputHandler}
					/>
					<FormInput
						id="telephone"
						element="input"
						type="text"
						label="Numer telefonu"
						validators={[VALIDATOR_NUMBER(), VALIDATOR_LENGTH(9)]}
						errorText="sprawdź numer telefonu"
						onInput={inputHandler}
					/>
					<RegistrationSelect
						id="roleFormat"
						value={roleFormat}
						variantsArray={roles}
						defaultValueTitle="Wybierz role"
						onChangeHandler={roleFormatSetter}
					/>
				</div>
				<div className="addUserModal__content-buttonArea">
					<Button onClick={closeModal} type="button">Powrót</Button>
					<Button
						type="button"
						disabled={!formState.isValid && roleFormat === ''}
						onClick={addUserInfo}
					>
						Zapisz
					</Button>
				</div>
			</div>
		</div>
	)
}

export default AddUserModal;
import React, {useContext, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import AddUserModal from '../../shared/modals/AddUserModal';
import sortAZ from '../../../assets/img/sortAZ.png';
import sortZA from '../../../assets/img/sortZA.png';
import addButton from '../../../assets/img/addButton.png';
import delButton from '../../../assets/img/delButton.png';
import openEye from '../../../assets/img/openEye.png';
import downloadPDF from '../../../assets/img/pdfIco.png';
import UserInfoModal from '../../shared/modals/UserInfoModal';

const UserTable = ({
					   titleArray,
					   drawArray,
					   activeSort,
					   changeSortName,
					   toggleSearchWay,
					   addTitle,
				   }) => {
	const {deleteUser, getUserPDF} = useContext(AppContext);
	const [userInfo, setUserInfo] = useState({});
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isUserInfoOpen, setIsUserInfoOpen] = useState(false)
	const isAddModalToggle = () => setIsAddModalOpen(prev => !prev);
	const isUserInfoToggle = () => setIsUserInfoOpen(prev => !prev);
	
	return (
		<>
			{
				drawArray.length
					?
					<table className="userTable">
						<thead>
						<tr>
							{
								titleArray.map((el, index) =>
									<td key={index} className="userTable__titleRow">
										<div className="userTable__titleCell">
											<p onClick={() => changeSortName(el)}>{el}</p>
											{
												activeSort.sortName === el
													?
													<img
														onClick={toggleSearchWay}
														src={activeSort.sortAZ ? sortAZ : sortZA}
														alt="sort arrow"
													/>
													:
													<div style={{width: '16px', height: '16px'}}/>
											}
										</div>
									</td>,
								)
							}
							<td className="userTable__titleRow">
								<div
									className="userTable__titleCell-addButton"
									onClick={() => {
										isAddModalToggle()
									}}
								>
									<p>{addTitle}</p>
									<img src={addButton} alt="add button"/>
								</div>
							</td>
						</tr>
						</thead>
						<tbody>
						{
							drawArray.map(el =>
								<tr key={el.id} className="userTable__contentRow">
									<td>
										<div className="userTable__contentCell">
											<p>{el.id}.</p>
										</div>
									</td>
									<td>
										<div
										>
											<p>{el.drug}</p>
										</div>
									</td>
									<td>
										<div
											className="userTable__contentCell"
										>
											<p>{el.role}</p>
										</div>
									</td>
									<td>
										<div
											className="userTable__contentCell"
										>
											<p>{el.surname} {el.name}</p>
										</div>
									</td>
									<td>
										<div
											className="userTable__contentCell"
										>
											<img style={{width: '30px', marginRight:'16px', cursor: 'pointer'}} onClick={()=>getUserPDF(el.id)} src={downloadPDF} alt="download pdf"/>
											<img
												className="userTable__contentCell-enableEdit"
												src={openEye}
												onClick={() => {
													setUserInfo({...el})
													isUserInfoToggle()
												}}
												alt="enable edit button"
											/>
											<img
												className="userTable__contentCell-enableEdit"
												src={delButton}
												onClick={() => {
													deleteUser(el.id);
												}}
												alt="del button"
											/>
										</div>
									</td>
								</tr>,
							)
						}
						</tbody>
					</table>
					:
					<div className="userTable__emptyTableMessage">
						<h1>NO DATA</h1>
					</div>
			}
			{
				isAddModalOpen && <AddUserModal closeModal={isAddModalToggle}/>
			}
			{
				isUserInfoOpen && <UserInfoModal closeModal={isUserInfoToggle} userInfo={userInfo}/>
			}
		</>
	)
}

export default UserTable;
import React, {useContext} from 'react';
import {AuthContext} from '../../../context/auth/authContext';
import {useForm} from '../../../hooks/form-hook';
import FormInput from '../../shared/formElements/FormInput';
import {VALIDATOR_EMAIL} from '../../../utils/validators';
import Button from '../../shared/formElements/Button';

const ResetPassword = () => {
	const {resetPassword} = useContext(AuthContext);
	const [formState, inputHandler] = useForm(
		{
			email: {
				value: '',
				isValid: false,
			},
		},
		false,
	)
	
	const submitFormHandler = (e) => {
		e.preventDefault();
		const data = {
			email: formState.inputs.email.value,
		}
		resetPassword(data)
	}
	
	return (
		<div className="reset">
			<div className="container">
				<div className="reset__title">
					<div className="reset__title-line"/>
					<h1>ODZYSKIWANIE HASŁA</h1>
					<div className="reset__title-line"/>
				</div>
				<form className="reset__content" onSubmit={submitFormHandler}>
					<FormInput
						id="email"
						element="input"
						type="text"
						label="Email"
						placeholder="e-mail"
						validators={[VALIDATOR_EMAIL()]}
						errorText="Check your mail"
						onInput={inputHandler}
						isAuthInput={true}
					/>
					<div className="auth__content-buttonArea">
						<Button type="submit" disabled={!formState.isValid}
								buttonClassName="authButton">RESETUJ</Button>
					</div>
				</form>
				<div className="reset__buttonArea">
					<Button to="/" buttonClassName="authButton">Powrót</Button>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
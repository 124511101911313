import React, {useContext, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {AppContext} from '../../../context/app/appContext';
import {NewCaseContext} from '../../../context/newCase/newCaseContext';
import NewCaseMenu from './NewCaseMenu';
import DashTitle from '../../shared/DashTitle';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DimensionError from '../../shared/DimensionError';
import LocalLoader from '../../shared/LocalLoader';

const NewCase = () => {
	const { appState: {userInfo: {drug}}} = useContext(AppContext);
	const { getCaseInfo, newCaseState, setStartedCaseInfo, clearNewCaseState} = useContext(NewCaseContext);
	const { width} = useWindowDimensions();
	
	useEffect(() => {
		return () => {
			clearNewCaseState();
		}
	}, [clearNewCaseState])
	
	useEffect(() => {
		if (!newCaseState.isLoaded) {
			newCaseState.id ? getCaseInfo(newCaseState.id) : setStartedCaseInfo()
		}
	}, [newCaseState, getCaseInfo, setStartedCaseInfo]);
	
	return (
		<>
			<div className="newCase">
				<div className="container">
					<DashTitle mainTitle={drug.diseaseType} subTitle="Dodawanie artykułu"/>
				</div>
				{
					newCaseState.isLoaded
						?
						<>
							<NewCaseMenu/>
							<div className="container">
								<Outlet/>
							</div>
						</>
						:
						<LocalLoader/>
				}
			
			</div>
			{width < 1024 && <DimensionError/>}
		</>
	)
}

export default NewCase;
import React, {useContext, useState} from "react";
import {AppContext} from "../../../context/app/appContext";
import sortAZ from "../../../assets/img/sortAZ.png";
import sortZA from "../../../assets/img/sortZA.png";
import ContactModal from "../../shared/modals/ContactModal";
import ChangeStatusModal from "../../shared/modals/ChangeStatusModal";
import ChangePayStatusModal from "../../shared/modals/ChangePayStatusModal";

const AdminTable = ({titleArray, drawArray, activeSort, changeSortName, toggleSearchWay}) => {
    const {getCasePDF} = useContext(AppContext);
    const [isContactModal, setIsContactModal] = useState(false);
    const [isStatusModal, setIsStatusModule] = useState(false);
    const [isPayModal, setIsPayModal] = useState(false);
    const [contacts, setContacts] = useState({
        author: '',
        email: '',
        telephone: '',
    })
    const [caseId, setCaseId] = useState(null);
    const [status, setStatus] = useState(null);

    const openContactModal = (author, email, telephone) => {
        setContacts({author, email, telephone})
        setIsContactModal(true)
    }

    const closeContactModal = () => {
        setIsContactModal(false)
        setContacts({
            author: '',
            email: '',
            telephone: '',
        })
    }

    const openStatusModal = (id) => {
        setIsStatusModule(true)
        setCaseId(id);
    }

    const closeStatusModal = () => {
        setIsStatusModule(false);
        setCaseId(null);
        setStatus(null);
    }

    const openPayModal = (id) => {
        setCaseId(id);
        setIsPayModal(true);
    }

    const closePayModal = () => {
        setIsPayModal(false);
        setCaseId(null);
    }

    return (
        <>
            {
                drawArray && drawArray.length > 0
                    ?
                    <table className="customTable">
                        <thead>
                        <tr>
                            {
                                titleArray.map((el, index) =>
                                    <td key={index} className="customTable__titleRow">
                                        <div className="customTable__titleCell">
                                            <p onClick={() => changeSortName(el.value)}>{el.name}</p>
                                            {
                                                activeSort.sortName === el.value
                                                    ?
                                                    <img
                                                        onClick={toggleSearchWay}
                                                        src={activeSort.sortAZ ? sortAZ : sortZA}
                                                        alt="sort arrow"
                                                    />
                                                    :
                                                    <div style={{width: '16px', height: '16px'}}/>
                                            }
                                        </div>
                                    </td>,
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            drawArray.map(el =>
                                <tr key={el.id} className="customTable__contentRow">
                                    <td>
                                        <div className="customTable__contentCell">
                                            <p>{el.id}.</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            className="customTable__contentCell"
                                            onClick={() => getCasePDF(el.id)}
                                        >
                                            <p>{el.title}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div
                                            className="customTable__contentCell"
                                            onClick={() => openContactModal(el.author, el.userEmail, el.userTelephone)}
                                        >
                                            <p>{el.author}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {
                                            el.status === 'do akceptacji' || el.status === 'do ponownej akceptacji'
                                                ?
                                                <div
                                                    className="customTable__contentCell"
                                                    onClick={() => openStatusModal(el.id)}
                                                >
                                                    <p>{el.status}</p>
                                                </div>
                                                :
                                                <div
                                                    className="customTable__payed"
                                                >
                                                    <p>{el.status}</p>
                                                </div>
                                        }
                                    </td>
                                    <td>
                                        {
                                            el.isPaid
                                                ?
                                                <div
                                                    className="customTable__payed"
                                                >
                                                    <p>Opłacone</p>
                                                </div>
                                                :
                                                <div
                                                    className="customTable__contentCell"
                                                    onClick={() => openPayModal(el.id)}
                                                >
                                                    <p>Nieopłacone</p>
                                                </div>
                                        }
                                    </td>
                                </tr>,
                            )
                        }
                        </tbody>
                    </table>
                    :
                    <div className="emptyTableMessage">
                        <h1>NO DATA</h1>
                    </div>
            }
            {
                isContactModal &&
                <ContactModal
                    closeModal={closeContactModal}
                    author={contacts.author}
                    email={contacts.email}
                    telephone={contacts.telephone}
                />
            }
            {
                isStatusModal &&
                <ChangeStatusModal
                    closeModal={closeStatusModal}
                    id={caseId}
                    status={status}
                />
            }
            {
                isPayModal &&
                <ChangePayStatusModal
                    id={caseId}
                    closeModal={closePayModal}
                />
            }
        </>
    )
}

export default AdminTable;
import React, {useContext} from 'react';
import {useForm} from '../../../hooks/form-hook';
import {AuthContext} from '../../../context/auth/authContext';
import FormInput from '../formElements/FormInput';
import {VALIDATOR_PASSWORD} from '../../../utils/validators';
import Button from '../formElements/Button';

const ChangePassword = ({togglePopup}) => {
	const {changePassword} = useContext(AuthContext);
	
	const [formState, inputHandler] = useForm(
		{
			oldPassword: {
				value: '',
				isValid: false,
			},
			newPassword: {
				value: '',
				isValid: false,
			},
			confirmPassword: {
				value: '',
				isValid: false,
			},
		},
		false,
	)
	
	const submitFormHandler = (e) => {
		e.preventDefault()
		changePassword(formState.inputs.oldPassword.value, formState.inputs.newPassword.value, formState.inputs.confirmPassword.value);
	}
	
	return (
		<div className="modal">
			<form
				className="modal__wrapper"
				onSubmit={submitFormHandler}
			>
				<FormInput
					id="oldPassword"
					element="input"
					type="password"
					label="Poprzednie hasło"
					validators={[VALIDATOR_PASSWORD()]}
					errorText="sprawdź hasło"
					onInput={inputHandler}
				/>
				<FormInput
					id="newPassword"
					element="input"
					type="password"
					label="Nowe hasło"
					validators={[VALIDATOR_PASSWORD()]}
					errorText="sprawdź hasło"
					onInput={inputHandler}
				/>
				<FormInput
					id="confirmPassword"
					element="input"
					type="password"
					label="Powtórz nowe hasło"
					validators={[VALIDATOR_PASSWORD()]}
					errorText="sprawdź hasło"
					onInput={inputHandler}
				/>
				<div className="modal__wrapper-buttonArea">
					<Button onClick={togglePopup} type='button'>Powrót</Button>
					<Button
						type="submit"
						disabled={!(formState.isValid && (formState.inputs.newPassword !== formState.inputs.confirmPassword))}
					>
						ZAPISZ ZMIANY
					</Button>
				</div>
			</form>
		</div>
	)
}

export default ChangePassword;
import React, {useEffect} from 'react';
import DashTitle from '../../shared/DashTitle';
import {useForm} from '../../../hooks/form-hook';
import {
	VALIDATOR_LENGTH,
	VALIDATOR_NUMBER,
	VALIDATOR_REQUIRE,
} from '../../../utils/validators';
import FormInput from '../../shared/formElements/FormInput';

const InvoiceForm = ({additionalValueSetter}) => {
	
	const [formState, inputHandler] = useForm(
		{
			company: {
				value: '',
				isValid: false,
			},
			address: {
				value: '',
				isValid: false,
			},
			nip: {
				value: '',
				isValid: false,
			},
			regon: {
				value: '',
				isValid: false,
			},
			account: {
				value: '',
				isValid: false,
			},
		},
		false,
	)
	
	useEffect(() => {
		// formState.isValid ? additionalValueSetter({
		additionalValueSetter({
			...formState.inputs,
			isValid: formState.isValid,
		})
		// }) : additionalValueSetter({isValid: false})
	}, [formState.isValid, formState.inputs, additionalValueSetter])
	
	return (
		<div className="invoiceForm">
			<DashTitle mainTitle="Invoice form" subTitle="company info"/>
			<FormInput
				id="company"
				element="input"
				type="text"
				label="Nazwa firmy"
				validators={[VALIDATOR_REQUIRE()]}
				errorText="Check Nazwa firmy"
				onInput={inputHandler}
			/>
			<FormInput
				id="address"
				element="input"
				type="text"
				label="Adres firmy"
				validators={[VALIDATOR_REQUIRE()]}
				errorText="Check adres"
				onInput={inputHandler}
			/>
			<FormInput
				id="nip"
				element="input"
				type="text"
				label="NIP firmy"
				placeholder="1234567890"
				validators={[VALIDATOR_LENGTH(10), VALIDATOR_NUMBER()]}
				errorText="Check NIP"
				onInput={inputHandler}
			/>
			<FormInput
				id="regon"
				element="input"
				type="text"
				label="REGON firmy"
				placeholder="123456789"
				validators={[VALIDATOR_LENGTH(9), VALIDATOR_NUMBER()]}
				errorText="Check REGON"
				onInput={inputHandler}
			/>
			<FormInput
				id="account"
				element="input"
				type="text"
				label="Rachunek firmy"
				placeholder="12345678912345678912345678"
				validators={[VALIDATOR_LENGTH(26), VALIDATOR_NUMBER()]}
				errorText="Check Rachunek"
				onInput={inputHandler}
			/>
		</div>
	)
}

export default InvoiceForm
import React from 'react';
import Button from '../formElements/Button';

const ValidationModal = ({errorArray, closeModal, clearArray}) => {
	return (
		<div className="validationModal">
			<div className="validationModal__content">
				<h3>Sprzawdź następujące pola:</h3>
				<ul>
					{
						errorArray.map((el, i) => <li key={i}>{el}</li>)
					}
				</ul>
				<div className="validationModal__content-buttonArea">
					<Button onClick={() => {
						clearArray();
						closeModal();
					}} type="button">Powrót</Button>
				</div>
			</div>
		</div>
	)
}

export default ValidationModal
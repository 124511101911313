import React, {useContext, useEffect, useState} from 'react';
// import {AppContext} from '../../../../context/app/appContext';
import NavButton from '../../../shared/NavButton';
import Button from '../../../shared/formElements/Button';
import QuillData from '../../../shared/QuillData';
import Select from '../../../shared/Select';
import SharedInput from '../../../shared/SharedInput';
import {NewCaseContext} from '../../../../context/newCase/newCaseContext';

const sexParams = [
	{
		name: 'Kobieta',
		value: 'women',
	},
	{
		name: 'Mężczyźna',
		value: 'men',
	},
]

const Page2 = () => {
	// const {appState: {caseInfo}} = useContext(AppContext);
	const {newCaseState, newCaseState: {pageValidation}, editCase} = useContext(NewCaseContext);
	const [isValidPage, setIsValidPage] = useState(false);
	
	const [sex, setSex] = useState(newCaseState.sex ? newCaseState.sex : 'women');
	const [age, setAge] = useState(newCaseState.age ? newCaseState.age : 0);
	const [quillData, setQuillData] = useState(newCaseState.description);
	
	const onSaveHandler = () => {
		editCase(null, sex, age, quillData, null, null, null);
	}
	
	const sexSetterHandler = (value) => setSex(value);
	const ageSetterHandler = (event) => setAge(event.target.value < 0 ? 0 : event.target.value);
	const quillSetterHandler = (value) => setQuillData(value);
	
	useEffect(() => {
		if (age < 0 || age >= 120) {
			setAge(newCaseState.age ? newCaseState.age : 0);
		}
	}, [age, newCaseState])
	
	useEffect(() => {
		sex !== '' && age > 0 && quillData && quillData.trim().length > 0 ? setIsValidPage(true) : setIsValidPage(false);
	}, [age, quillData, sex])
	
	return (
		<div className="nc2">
			<NavButton isActive={true} to="/newCase/1" left/>
			<div className="nc2__content">
				<div className="nc2__content-dataArea">
					<Select name="sex" value={sex} sortingValueHandler={sexSetterHandler} params={sexParams}
							label="Płeć"/>
					<SharedInput
						id="age"
						type="number"
						label="Wiek pacjenta:"
						value={age}
						setter={ageSetterHandler}
					/>
					<h3>Opis choroby - od kiedy, jakie są główne objawy</h3>
					<h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id integer massa eget pulvinar est.
						Ipsum dignissim varius sem etiam tristique tempor, in euismod commodo.</h6>
					<QuillData quillData={quillData} quillSetterHandler={quillSetterHandler}/>
				</div>
				<div className="nc2__content-buttonArea">
					<Button to="/">ANULUJ</Button>
					<Button
						type="button"
						disabled={!isValidPage}
						onClick={onSaveHandler}
					>ZAPISZ</Button>
				</div>
			</div>
			<NavButton isActive={pageValidation && pageValidation.p2} to="/newCase/3"/>
		</div>
	)
}

export default Page2;
import React, {useContext} from 'react';
import {AuthContext} from '../../../context/auth/authContext';
import {useForm} from '../../../hooks/form-hook';
import Button from '../../shared/formElements/Button';
import FormInput from '../../shared/formElements/FormInput';
import {
	VALIDATOR_EMAIL,
	VALIDATOR_NOT_REQUIRE,
	VALIDATOR_PASSWORD,
} from '../../../utils/validators';

const Auth = () => {
	const {authUser} = useContext(AuthContext);
	const [formState, inputHandler] = useForm(
		{
			email: {
				value: '',
				isValid: false,
			},
			pwz: {
				value: '',
				isValid: true,
			},
			password: {
				value: '',
				isValid: false,
			},
		},
		false,
	)
	
	const submitFormHandler = (e) => {
		e.preventDefault();
		
		const data = {
			email: formState.inputs.email.value,
			pwz: formState.inputs.pwz.value,
			password: formState.inputs.password.value,
		}
		
		authUser(data);
	}
	
	return (
		<div className="auth">
			<div className="container">
				<div className="auth__title">
					<div className="auth__title-line"/>
					<h1>LOGOWANIE</h1>
					<div className="auth__title-line"/>
				</div>
				<form className="auth__content" onSubmit={submitFormHandler}>
					<FormInput
						id="email"
						element="input"
						type="text"
						label="Email"
						placeholder="e-mail"
						validators={[VALIDATOR_EMAIL()]}
						errorText="Check your mail"
						onInput={inputHandler}
						isAuthInput={true}
					/>
					<FormInput
						id="pwz"
						element="input"
						type="text"
						label="pwz"
						placeholder="PWZ"
						validators={[VALIDATOR_NOT_REQUIRE()]}
						errorText="Check your PWZ"
						onInput={inputHandler}
						initialValid={true}
						isAuthInput={true}
					/>
					<FormInput
						id="password"
						element="input"
						type="password"
						label="password"
						placeholder="password"
						validators={[VALIDATOR_PASSWORD()]}
						errorText="Check your password"
						onInput={inputHandler}
						isAuthInput={true}
					/>
					<div className="auth__content-buttonArea">
						<Button type="submit" disabled={!formState.isValid} buttonClassName='authButton'>ZALOGUJ SIĘ</Button>
					</div>
				</form>
				<div className="auth__buttonArea">
					<Button to="/registration" buttonClassName='authButton'>Zarejestruj się</Button>
					<span>|</span>
					<Button to="/reset-password" buttonClassName='authButton'>Zapomniałeś hasła?</Button>
				</div>
			</div>
		</div>
	)
}

export default Auth;
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import {AuthState} from './context/auth/AuthState';
import {AppState} from './context/app/AppState';
import {NewCaseState} from './context/newCase/NewCaseState';
import {PoiState} from './context/poi/PoiState';


const app = (
	<BrowserRouter>
		<PoiState>
			<AuthState>
				<AppState>
					<NewCaseState>
						<App/>
					</NewCaseState>
				</AppState>
			</AuthState>
		</PoiState>
	</BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'));

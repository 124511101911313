import React from 'react';

const DashTitle = ({mainTitle, subTitle}) => {
	return (
		<div className='dashTitle'>
			<div className='dashTitle__decorLine'/>
			<div className='dashTitle__textArea'>
				<h1 className='dashTitle__mainTitle'>{mainTitle}</h1>
				{
					subTitle && <h3 className='dashTitle__subTitle'>{subTitle}</h3>
				}
			</div>
			<div className='dashTitle__decorLine'/>
		</div>
	)
}

export default DashTitle;
import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from '../../../context/app/appContext';
import Button from '../formElements/Button';
import Select from '../Select';

const paramsStatus = [
	'zaakceptowane do publikacji',
	'zaakceptowane nie do publikacji',
	'zamknięte',
	'uwagi',
]

const ChangeStatusModal = ({id, closeModal}) => {
	
	const {changeStatus} = useContext(AppContext);
	
	const [statusValue, setStatusValue] = useState(paramsStatus[0]);
	const [medicalNumber, setMedicalNumber] = useState('');
	const [isValid, setIsValid] = useState(false)
	
	const setStatusValueHandler = (value) => setStatusValue(value);
	const changeMedicalNumberHandler = (value) => setMedicalNumber(value);
	
	useEffect(() => {
		statusValue === 'zaakceptowane do publikacji' ?
			medicalNumber.trim().length ? setIsValid(true) : setIsValid(false)
			: setIsValid(true)
	}, [medicalNumber, statusValue])
	
	return (
		<div className="statusModal">
			<div className="statusModal__content">
				<h3>Change status?</h3>
				{
					statusValue &&
					<Select
						value={statusValue}
						label="Status"
						name="status"
						params={paramsStatus}
						sortingValueHandler={setStatusValueHandler}
						moderator
					/>
				}
				{
					statusValue === 'zaakceptowane do publikacji' &&
					<div className="formControl">
						<label htmlFor="medicalNumber">
							medical number:
							<input
								type="text"
								value={medicalNumber}
								onChange={(e) => changeMedicalNumberHandler(e.target.value)}
							/>
						</label>
					</div>
				}
				<div className="statusModal__content-buttonArea">
					<Button onClick={closeModal} type="button">Powrót</Button>
					<Button onClick={() => {
						changeStatus(id, statusValue, medicalNumber);
						closeModal()
					}} type="button" disabled={!isValid}>Change</Button>
				</div>
			</div>
		</div>
	)
}

export default ChangeStatusModal;
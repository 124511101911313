import React from 'react';
import SubInfo from './SubInfo';
import {useLocation} from 'react-router-dom';

const Page3 = () => {
	const location = useLocation();
	const pageNumber = location.pathname.split('/')[2]
	return <SubInfo pageNumber={pageNumber}/>
}

export default Page3;